import React, { useContext, useEffect, useRef } from "react"
import { FormControl, InputGroup } from "react-bootstrap"

import PlusButton from "components/BuildersMerchantPage/AddToListButton";
import classNames from "classnames"

import styles from "./SearchBar.module.scss"
import { BuildersMerchantsContext } from "src/context/BuildersMerchantsContext";

const SearchBar = ({ isEnabled = false, onSubmit, handleSearchBarTouch }: SearchBarProps) => { 

  const buildersMerchantsContext = useContext(BuildersMerchantsContext);
  const { value: searchBarText, setValue: setSearchBarText } = buildersMerchantsContext.searchBarContext;

  const searchBarRef = useRef(null);
  
  const setFocus = () => {
    searchBarRef.current.focus();
  };
  
  useEffect(() => {
    if (isEnabled) {
      setTimeout(setFocus, 0);
    }
  }, [isEnabled]);

  const handleKeypress = (e) => {
    if (e.keyCode === 13) {
      submitSearch();
    }
  };

  const submitSearch = () => {
    onSubmit();
  }

  const searchBarStyles = classNames('mb-3', 'custom-search', 'justify-content-center', styles.customSearch)
  
  return (
    <>
      <InputGroup id="search-bar" className={searchBarStyles}>
        <FormControl
          readOnly={!isEnabled}
          placeholder="Search for product/material"
          aria-label="Search for product/material"
          aria-describedby="basic-addon2"
          value={searchBarText}
          onClick={handleSearchBarTouch}
          onChange={(e) => setSearchBarText(e.target.value)}
          onKeyUp={(e) => handleKeypress(e)}
          ref={searchBarRef}
        />
      </InputGroup>
      <PlusButton
        isEnabled={isEnabled}
        click={() => {
          submitSearch();
        }}
      />
    </>
  )
}

export default SearchBar
