import React from "react"
import { Button } from "react-bootstrap"
import {BiPlus} from "react-icons/bi"
import styles from "./AddToListButton.module.scss"

const AddToListButton = ({ isEnabled, click }: AddToListButtonProps) => { 
  return (
    <Button id="plus-button"
      disabled={!isEnabled}
      variant='success'  
      className={styles.plusButton}
          onClick={() => click() }>
          <BiPlus size={30} className={styles.plusIcon}/>
    </Button>
  )
}

export default AddToListButton
