import 'regenerator-runtime/runtime';
import React, { useContext, useEffect, useState } from 'react';
import { Container, Col, Row } from "react-bootstrap";
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';

import Layout from "components/layout";
import SEO from "components/seo";
import ResultsTable from "components/BuildersMerchantPage/ResultsTable";
import SearchBar from "components/BuildersMerchantPage/SearchBar";
import SearchSettings from "components/BuildersMerchantPage/SearchSettings";
import SwipeSearchSelector from "components/BuildersMerchantPage/SwipeSearchSelector";
import ProgressButtons from "components/BuildersMerchantPage/ProgressButtons";
import { BuildersMerchantsContext } from 'src/context/BuildersMerchantsContext';
import { 
    transcriptMaterialsMatcher, 
    transcriptNumberMatcher, 
    transcriptManufacturerMatcher, 
    transcriptVariationMatcher 
} from 'src/utils/voiceSearch-utils';
import { navigate } from 'gatsby';

const errorText = "No materials have been recognised. Please refresh the page and try again.";

const tableHeadings = [
    "Product",
    "Manufacturer",
    "Quantity",
    "Variation",
    "",
];

const BuildersMerchantPage = () => {
    const [canTextSearch, setCanTextSearch] = useState(false);
    const [searchData, setSearchData] = useState('');
    const [isSearchBarDirty, setDirtySearchBar] = useState(false);

    const buildersMerchantsContext = useContext(BuildersMerchantsContext);
    const { value: searchBarText, setValue: setSearchBarText } = buildersMerchantsContext.searchBarContext;
    const { value: productSearchItems, setValue: setProductSearchItems } = buildersMerchantsContext.productsContext;
    
    const [errorMessage, setErrorMessage] = useState("");

    const commands = [
        {
            command: 'Next',
            callback: () => {
                processVoiceSearch(searchBarText);
                resetTranscript();
            },
        },
        {
            command: 'Compare',
            callback: () => {
                listeningComplete();
                navigate("/compare/");
            },
        },
    ]
    
    const {
        interimTranscript,
        finalTranscript,
        resetTranscript,  
    } = useSpeechRecognition({ commands });
      
    useEffect(() => {
        if (!!finalTranscript) {
            setSearchBarText(finalTranscript);
        }
    }, [interimTranscript, finalTranscript]);
    
    const listenContinuously = () => {
        SpeechRecognition.startListening({
        continuous: true,
        language: 'en-GB',
        });
    };
    
    const processVoiceSearch = (voiceData) => {
        try {
            setSearchData(voiceData);
        } catch (err) {
            console.log(err)
        }
    }

    // Effect to populate product list
    useEffect(() => {
        const getResponse = async () => {
            if (!!!searchData) {
                return;
            }

            const transcribedData = searchData && transcriptNumberMatcher(searchData);
            const matchedNumber = transcribedData && transcribedData.matchedNumber;

            var material = searchData && transcriptMaterialsMatcher(searchData);
            var manufacturer = searchData && transcriptManufacturerMatcher(searchData);
            const variation = searchData && transcriptVariationMatcher(searchData);

            if (!!!material.length && !!!manufacturer.length) {
                setErrorMessage(errorText);
                return;
            };

            setProductSearchItems([
                ...productSearchItems,
                {
                    material: material[0],
                    manufacturer: manufacturer[0],
                    quantity: matchedNumber,
                    variation: variation[0]
                },
            ]);

            setSearchBarText('');
            setSearchData(null);

            return;
        };
        getResponse();
    }, [searchData]);

    const showResults = !!productSearchItems;
    const showError = showResults && !!!productSearchItems.length;

    const onDeleteRow = (rowIndex: number) => {
        let currentProductSearchList = [...productSearchItems];        
        currentProductSearchList.splice(rowIndex, 1);

        setProductSearchItems(currentProductSearchList);
    }

    const listeningComplete = () => {
        SpeechRecognition.stopListening();
        resetTranscript();
        setSearchBarText('');
        setSearchData(null);
    }

    const handleSwipeChange = (value: number) => {
        listeningComplete();

        switch (value) {
            case 1:
                setCanTextSearch(false);
                
                listenContinuously();
                break;
      
            case -1:
                setCanTextSearch(true);
              break;
          
            default:
                setCanTextSearch(false);
                setDirtySearchBar(false);
              break;
          }
    }

    const handleSearchBarSubmit = () => {
        setSearchData(searchBarText);
    }

    const handleSearchBarTouch = () => {
        setCanTextSearch(true);
        setDirtySearchBar(true);
    };

    return (
        <div style={{ backgroundColor: '#FFFFFF' }}>
            <Layout pageInfo={{ pageName: "results" }}>
                <SEO title="Results" keywords={[`gatsby`, `react`, `bootstrap`]} />
                <Container fluid className="px-0"  style={{paddingTop:"10px"}}>
                    <Row className="justify-content-center">
                        <Col xs={10}>
                            <h2 className="display-5 text-center" style={{color:"black"}}>CREATE</h2>
                        </Col>
                    </Row>
                    <Row className="justify-content-center">
                        <Col xs={12}>
                            <SwipeSearchSelector isSearchBarDirty={isSearchBarDirty} onSwipeChange={handleSwipeChange} />
                        </Col>
                        <Col xs={12}>
                            <SearchSettings />
                        </Col>
                        <Col xs={10} sm={8} md={6} lg={5} className="justify-content-center align-items-center text-center">
                            <SearchBar handleSearchBarTouch={handleSearchBarTouch} isEnabled={canTextSearch} onSubmit={handleSearchBarSubmit} />
                        </Col>
                        {showResults && <Col xs={11} sm={9} md={7} lg={8} className="justify-content-center align-items-center text-center" style={{marginBottom:"1em"}}>
                            <ResultsTable tableHeadings={tableHeadings} rows={productSearchItems} deleteRow={onDeleteRow} />
                            {showError && <p className='text-center'>{errorMessage}</p>}
                        </Col>}
                        <Col xs={12} sm={10} md={8} lg={6}> 
                            <ProgressButtons />
                        </Col>
                    </Row>
                </Container>
            </Layout>
        </div>
    )
}

export default BuildersMerchantPage
