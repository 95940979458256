import React, { useContext, useEffect, useState } from "react";
import ReactSlider from "react-slider";
import styles from "./SwipeSearchSelector.module.scss";
import classNames from "classnames";
import { Button, Form, Col, Row } from "react-bootstrap";
import { BuildersMerchantsContext } from 'src/context/BuildersMerchantsContext';

const buttonStyle = classNames("btn", "btn-light");

const SwipeSearchSelector = ({ onSwipeChange, isSearchBarDirty }: SwipeSearchSelectorProps) => {
  const buildersMerchantsContext = useContext(BuildersMerchantsContext);
  const { value: swipeValue, setValue } = buildersMerchantsContext.swiperContext;

  const [instructions, setInstructions] = useState("Swipe to search by mic or text");
  const [textEnabledStyle, setTextEnabledStyle] = useState(styles.disabled);
  const [voiceEnabledStyle, setVoiceEnabledStyle] = useState(styles.disabled);

  // Toggle the logo to voice recognition, or text input, or off at centre toggle
  const handleSliderChange = (sliderValue) => {
    onSwipeChange(sliderValue);
    setValue(sliderValue);

    switch (sliderValue) {
      case 1:
        setTextEnabledStyle(styles.disabled);
        setVoiceEnabledStyle(styles.enabled);

        setInstructions("Voice Recognition is on");
        break;

      case -1:
        setTextEnabledStyle(styles.enabled);
        setVoiceEnabledStyle(styles.disabled);

        setInstructions('Text search has been toggled on');
        break;

      default:
        setTextEnabledStyle(styles.disabled);
        setVoiceEnabledStyle(styles.disabled);

        setInstructions('Swipe to search by voice or text');
        break;
    }
  }

  useEffect(() => {
    if(isSearchBarDirty) handleSliderChange(-1);
  }, [isSearchBarDirty]);

  const textSearchClass = classNames(buttonStyle, textEnabledStyle)
  const voiceSearchClass = classNames(buttonStyle, voiceEnabledStyle)

  return (
    <>
      <Row>
        <Col>
          <p id="instructions" className={styles.instructText}>
            {instructions}
          </p>
        </Col>
      </Row>
      <Row>
        <Col>
          <Form>
            <Form.Row className="justify-content-center">
              <Col></Col>
            </Form.Row>
            <Form.Row
              className={`justify-content-center align-items-center ${styles.h200px}`}
            >
              <Col xs={1.5} className={styles.textSearchBtnCol}>
                <Button id="text-search-btn" className={textSearchClass}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    className="bi bi-search"
                    viewBox="0 0 16 16"
                  >
                    <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z"></path>
                  </svg>
                </Button>
              </Col>
              <Col xs={6} sm={5} md={3} lg={2}>
                <Form.Group controlId="formBasicRange">
                  <ReactSlider
                    className={styles.slider}
                    thumbClassName={ swipeValue === 0 ? `${styles.thumb} ${styles.pulsating}` : `${styles.thumb}` } 
                    min={-1}
                    max={1}
                    value={swipeValue}
                    onChange={handleSliderChange}
                  />
                </Form.Group>
              </Col>
              <Col xs={1.5} className={styles.voiceSearchBtnCol}>
                <Button id="voicerec-search-btn" className={voiceSearchClass}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    className="bi bi-mic-fill"
                    viewBox="0 0 16 16"
                  >
                    <path d="M5 3a3 3 0 0 1 6 0v5a3 3 0 0 1-6 0V3z"></path>
                    <path d="M3.5 6.5A.5.5 0 0 1 4 7v1a4 4 0 0 0 8 0V7a.5.5 0 0 1 1 0v1a5 5 0 0 1-4.5 4.975V15h3a.5.5 0 0 1 0 1h-7a.5.5 0 0 1 0-1h3v-2.025A5 5 0 0 1 3 8V7a.5.5 0 0 1 .5-.5z"></path>
                  </svg>
                </Button>
              </Col>
            </Form.Row>
          </Form>
        </Col>
      </Row>
    </>
  )
}

export default SwipeSearchSelector
