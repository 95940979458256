import React from "react"
import { Button, Col, Row } from "react-bootstrap"
import styles from "./SearchSettings.module.scss"

const SearchSettings = ({ }: SearchSettingsProps) => { 
  return (
    <>
      <Row id="search-settings-btns" className={styles.SearchSetBtns}>
        <Col xs={3} sm={2.5} md={2} lg={1} className="text-center">
          <Button id="advanced-btn" className="btn-light" data-bs-toggle="modal" data-bs-target="#advSearchModal">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-gear-fill" viewBox="0 0 16 16">
                <path d="M9.405 1.05c-.413-1.4-2.397-1.4-2.81 0l-.1.34a1.464 1.464 0 0 1-2.105.872l-.31-.17c-1.283-.698-2.686.705-1.987 1.987l.169.311c.446.82.023 1.841-.872 2.105l-.34.1c-1.4.413-1.4 2.397 0 2.81l.34.1a1.464 1.464 0 0 1 .872 2.105l-.17.31c-.698 1.283.705 2.686 1.987 1.987l.311-.169a1.464 1.464 0 0 1 2.105.872l.1.34c.413 1.4 2.397 1.4 2.81 0l.1-.34a1.464 1.464 0 0 1 2.105-.872l.31.17c1.283.698 2.686-.705 1.987-1.987l-.169-.311a1.464 1.464 0 0 1 .872-2.105l.34-.1c1.4-.413 1.4-2.397 0-2.81l-.34-.1a1.464 1.464 0 0 1-.872-2.105l.17-.31c.698-1.283-.705-2.686-1.987-1.987l-.311.169a1.464 1.464 0 0 1-2.105-.872l-.1-.34zM8 10.93a2.929 2.929 0 1 1 0-5.86 2.929 2.929 0 0 1 0 5.858z"></path>
            </svg>
          </Button>            
        </Col>
        <Col xs={3} sm={2.5} md={2} lg={1} className="text-center">
          {/* <!-- Button triggers Optimized Search Modal --> */}
          <Button id="optimize-btn" className="btn btn-light text-center" data-bs-toggle="modal" data-bs-target="#optSearchModal">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-lightning-fill" viewBox="0 0 16 16">
              <path d="M5.52.359A.5.5 0 0 1 6 0h4a.5.5 0 0 1 .474.658L8.694 6H12.5a.5.5 0 0 1 .395.807l-7 9a.5.5 0 0 1-.873-.454L6.823 9.5H3.5a.5.5 0 0 1-.48-.641l2.5-8.5z"></path>
            </svg>
          </Button>
        </Col>
      </Row>     

      {/* <!-- Advanced Search Modal --> */}
      <div className="modal fade" id="advSearchModal" tabIndex={-1} aria-labelledby="advModalLabel" aria-hidden="true">
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-header">
                        <h2 className="modal-title" id="advModalLabel">Advanced Search</h2>
                        <Button className="btn-close" data-bs-dismiss="modal" aria-label="Close"></Button>
                    </div>
                    <div className="modal-body">
                      <p id="adv-modal-title" className="modal-body-title">Focus the search on materials that are:</p>
                      <div className="form-check">
                        <div className="adv-modal-checkboxandtext">
                          <input className="form-check-input" type="checkbox" id="flexCheckChecked" defaultChecked={true} />
                                <label className="form-check-label" htmlFor="flexCheckChecked">In Stock</label>
                            </div>
                            <p id="adv-modal-option-instock" className="modal-body-text">AND</p>
                            <div className="adv-modal-checkboxandtext">
                                <input className="form-check-input" type="checkbox" id="flexCheckDefault" defaultChecked={true}/>
                                <label className="form-check-label" htmlFor="flexCheckDefault">Are the cheapest</label>
                            </div>
                            <p id="adv-modal-option-green" className="modal-body-text">OR</p>
                            <div className="adv-modal-checkboxandtext">
                                <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault"/>
                                <label className="form-check-label" htmlFor="flexCheckDefault">Are the greenest</label>
                            </div>
                            <p id="adv-modal-option-closest" className="modal-body-text">OR</p>
                            <div className="adv-modal-checkboxandtext">
                                <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault"/>
                                <label className="form-check-label" htmlFor="flexCheckDefault">Are the closest distance</label>
                            </div>
                            <p></p>
                            <div className="d-flex">
                                System autoselects specs
                                <div className="form-switch ms-2">
                                    <input type="checkbox" className="form-check-input" id="site_state" defaultChecked={true}/>
                                </div>
                                <label htmlFor="site_state" className="form-check-label">User selects specs</label>
                            </div>
                            <p></p>
                            <p id="adv-modal-title" className="modal-body-title">Narrow the search to merchants that:</p>
                            <p id="adv-modal-range-text" className="modal-body-text2">Are within</p>
                            <div className="d-flex">
                                0 miles
                                <input id="customRange2" type="range" className="form-range" min="0" max="250" defaultValue="50" step="25" list="tickmarks"/>
                                <datalist id="tickmarks">
                                    <option value="0"></option>
                                    <option value="50"></option>
                                    <option value="100"></option>
                                    <option value="150"></option>
                                    <option value="200"></option>
                                    <option value="250"></option>
                                </datalist>
                                <label htmlFor="customRange2" className="form-range-label">250 miles</label>
                            </div>
                            <p></p>
                            <div className="adv-modal-checkboxandtext">
                                <input className="form-check-input" type="checkbox" id="flexCheckDefault" defaultChecked={true}/>
                                <label className="form-check-label" htmlFor="flexCheckDefault">Deliver to my region/ location as standard</label>
                            </div>
                            <p id="adv-modal-option-closest" className="modal-body-text">OR</p>
                            <div className="adv-modal-checkboxandtext">
                                <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault"/>
                                <label className="form-check-label" htmlFor="flexCheckDefault">Will deliver to my region for an additional fee</label>
                            </div>
                            <p id="adv-modal-option-closest" className="modal-body-text">AND</p>
                            <div className="adv-modal-checkboxandtext">
                                <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault"/>
                                <label className="form-check-label" htmlFor="flexCheckDefault">That have all my order list in stock</label>
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer">
                    <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                    <button type="button" className="btn btn-primary">Save changes</button>
                    </div>
                </div>
            </div>
        </div>

        {/* <!-- Optimized Search Modal --> */}
      <div className="modal fade" id="optSearchModal" tabIndex={-1} aria-labelledby="optModalLabel" aria-hidden="true">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                  <h2 className="modal-title" id="optModalLabel">Optimize Results</h2>
                      <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div className="modal-body">
                <p id="adv-modal-title" className="modal-body-title">Optimize results based on your project</p>
                <p id="adv-modal-range-text" className="modal-body-text2">Select the type of project the materials are for:</p>
                <div className="form-check">
                  <div className="dropdown mt-3">
                    <button className="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton" data-bs-toggle="dropdown">
                      Project Type
                    </button>
                    <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                    <li><a className="dropdown-item" href="#">Extension</a></li>
                    <li><a className="dropdown-item" href="#">Garage</a></li>
                    <li><a className="dropdown-item" href="#">Garden pation/ decking</a></li>
                    <li><a className="dropdown-item" href="#">Home Renovation</a></li>
                    <li><a className="dropdown-item" href="#">New Home</a></li>
                    </ul>
                  </div>
                  
                  <p id="adv-modal-title" className="modal-body-title">Optimize results based on your materials requirements</p>
                  <p id="adv-modal-range-text" className="modal-body-text2">Select the type of materials required:</p>
                  
                  <div className="adv-modal-checkboxandtext">
                    <input className="form-check-input" type="checkbox" value="" id="allMtrlsChkbox"/>
                    <label className="form-check-label" htmlFor="flexCheckDefault">All</label>
                  </div>
                  <div className="adv-modal-checkboxandtext">
                    <input className="form-check-input" type="checkbox" value="" id="aggAndMasMtrlsChkbox"/>
                    <label className="form-check-label" htmlFor="flexCheckDefault">Aggregates & Masonry</label>
                  </div>
                  <div className="adv-modal-checkboxandtext">
                    <input className="form-check-input" type="checkbox" value="" id="carpentryMtrlsChkbox"/>
                    <label className="form-check-label" htmlFor="flexCheckDefault">Carpentry</label>
                  </div>
                  <div className="adv-modal-checkboxandtext">
                    <input className="form-check-input" type="checkbox" value="" id="electricalMtrlsChkbox"/>
                    <label className="form-check-label" htmlFor="flexCheckDefault">Electrical</label>
                  </div>
                  <div className="adv-modal-checkboxandtext">
                    <input className="form-check-input" type="checkbox" value="" id="finishesMtrlsChckbox"/>
                    <label className="form-check-label" htmlFor="flexCheckDefault">Finishes</label>
                  </div>
                  <div className="adv-modal-checkboxandtext">
                    <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault"/>
                    <label className="form-check-label" htmlFor="flexCheckDefault">Heating</label>
                  </div>
                  <div className="adv-modal-checkboxandtext">
                    <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault"/>
                    <label className="form-check-label" htmlFor="flexCheckDefault">Interior</label>
                  </div>
                  <div className="adv-modal-checkboxandtext">
                    <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault"/>
                    <label className="form-check-label" htmlFor="flexCheckDefault">Plumbing</label>
                  </div>
                  <div className="adv-modal-checkboxandtext">
                    <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault"/>
                    <label className="form-check-label" htmlFor="flexCheckDefault">Steelwork</label>
                  </div>
                  <p></p>
                  <p id="adv-modal-range-text" className="modal-body-text2">Select the state of the materials you wish to search for:</p>
                  <div className="adv-modal-checkboxandtext">
                    <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault"/>
                    <label className="form-check-label" htmlFor="flexCheckDefault">All</label>
                  </div>
                  <div className="adv-modal-checkboxandtext">
                    <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault"/>
                    <label className="form-check-label" htmlFor="flexCheckDefault">Brand new (sold by licenced merchants)</label>
                  </div>
                  <div className="adv-modal-checkboxandtext">
                    <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault"/>
                    <label className="form-check-label" htmlFor="flexCheckDefault">Second hand (sold by third party)</label>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
              <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
              <button type="button" className="btn btn-primary">Save changes</button>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default SearchSettings
