import React, { useEffect, useState } from "react";
import { Table, Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilter } from "@fortawesome/free-solid-svg-icons";
import styles from "./ResultsTable.module.scss";
import {BiTrashAlt} from "react-icons/bi"

const ResultsTable: React.FC<MerchantResultsTableProps> = ({
  tableHeadings,
  rows,
  deleteRow,
}) => {
  const [tableRows, setTableRows] = useState(rows);
  const [drag, setDrag] = useState(null);
  const [width, setWidth] = useState(`${100 / tableHeadings.length}%`);

  const handleStart = ({ clientX }, rowIndex, colIndex) => {
    const iniSize = document.getElementById(`${rowIndex}${colIndex}`)?.offsetWidth;
    setDrag({
      iniMouse: clientX,
      iniSize: iniSize,
    });
  };

  const handleMove = ({ clientX }) => {
    if (clientX) {
      const { iniMouse, iniSize } = drag;
      const endSize = iniSize + (clientX - iniMouse);
      setWidth(`${endSize}px`);
    };
  };

  const handleDoubleClick = () => {
    width === "max-content" ? setWidth("auto") : setWidth("max-content");
  };

  useEffect(() => {
    if (!!rows) {
      setTableRows(rows);
    }
  }, [rows]);

  const handleDeleteRow = (rowIndex: number) => {
    deleteRow(rowIndex);
  }

  const showTable = !!rows.length;

  const columnWidth = `${100 / tableHeadings.length}%`;

  const rowStyle = {
    display: "flex",
    width: `${100 * tableHeadings.length}%`,
  };

  const cellStyle = {
    position: "relative",
    width
  };

  const readOnlyColumnKeys = {
    cost: true,
    total: true,
    description: true
  }
  return (
    <div className={styles.tableWrapper}>
      {showTable && (
         <Table
          striped
          bordered
          hover
          variant="dark"
          className={styles.resultsTable}
        >
          <thead>
            <tr>
              <th className={`${styles.hiddenElement} d-none d-lg-block`} style={{ width: columnWidth }} />
              {tableHeadings.map((title, index) => (
                <th
                  key={index}
                  style={{ verticalAlign: "top", width: columnWidth }}
                  className={styles.heading}
                >
                  {!title ? title : (
                    <>
                  {title}
                  <FontAwesomeIcon
                    icon={faFilter}
                    className={styles.filterIcon}
                  />
                    </>
                  )}
                </th>
              ))}
            </tr>
          </thead>
          <tbody className={styles.scrollableBody}>
            {tableRows.map((row, rowIndex) => (
              <tr key={rowIndex} style={rowStyle}>
                <td className={`${styles.hiddenElement} d-none d-lg-block`} style={{ width }} />
                {Object.keys(row).map((key, colIndex) => (
                <td key={colIndex} id={`${rowIndex}${colIndex}`} style={cellStyle}>
                    <input
                      type="text"
                      style={{ textOverflow: "ellipsis" }}
                      readOnly={readOnlyColumnKeys[key]}
                      value={row[key]}
                      onChange={(e) => {
                        let currentRows = [...tableRows];
                        currentRows[rowIndex][key] = e.target.value;
                        setTableRows(currentRows);
                      }}
                    />
                      <div
                        className={styles.dragger}
                        onDoubleClick={handleDoubleClick}
                        draggable
                        onDragStart={(e) => handleStart(e, rowIndex, colIndex)}
                        onDrag={(e) => handleMove(e)}
                      />
                  </td>
                ))}
                <td key={rowIndex}>
                  <Button id="delete-button"
                  variant='success'
                  className={styles.deleteButton}
                  onClick={() => handleDeleteRow(rowIndex) }>
                    <BiTrashAlt size={30} className={styles.deleteIcon}/>
                </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table> 
      )}
    </div>
  );
};

export default ResultsTable;